var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        { ref: "form", attrs: { name: "formCompany" } },
        [
          _c(
            "b-card-actions",
            {
              ref: "main-card",
              attrs: {
                "no-actions": "",
                "show-loading": _vm.loading || _vm.busy,
                title: _vm.$t("Dados Cadastrais"),
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "1" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "company-id",
                          name: "id",
                          label: _vm.$t("Id"),
                          type: _vm.isEdition ? "label" : "number",
                          validation: "required",
                        },
                        model: {
                          value: _vm.company.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "id", $$v)
                          },
                          expression: "company.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "identification",
                          name: "identification",
                          label: _vm.$t("Identificação"),
                          placeholder: "M01, F01, Q01, etc.",
                          type: "text",
                          validation: "required",
                        },
                        model: {
                          value: _vm.company.identification,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "identification", $$v)
                          },
                          expression: "company.identification",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "company-name",
                          name: "companyName",
                          label: _vm.$t("Razão Social"),
                          type: "text",
                          validation: "required",
                        },
                        model: {
                          value: _vm.company.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "companyName", $$v)
                          },
                          expression: "company.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "trading-name",
                          name: "tradingName",
                          type: "text",
                          label: _vm.$t("Nome Fantasia"),
                          validation: "required",
                        },
                        model: {
                          value: _vm.company.tradingName,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "tradingName", $$v)
                          },
                          expression: "company.tradingName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("e-company-combo", {
                        attrs: {
                          id: "headquarters",
                          name: "headquartersCompanyId",
                          required: false,
                          label: _vm.$t("Matriz"),
                        },
                        model: {
                          value: _vm.company.headquartersCompanyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "headquartersCompanyId", $$v)
                          },
                          expression: "company.headquartersCompanyId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "type",
                          name: "type",
                          type:
                            _vm.isEdition && _vm.company.type === "Kiosk"
                              ? "label"
                              : "vue-select",
                          validation: "required",
                          label: _vm.$t("Tipo"),
                          options: _vm.companyTypes(),
                          placeholder: _vm.$t("Selecione"),
                        },
                        model: {
                          value: _vm.company.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "type", $$v)
                          },
                          expression: "company.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "region",
                          name: "region",
                          type: "label",
                          label: _vm.$t("Região"),
                        },
                        model: {
                          value: _vm.company.region,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "region", $$v)
                          },
                          expression: "company.region",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "cnpj",
                          name: "cnpj",
                          type: "text-mask",
                          mask: ["##.###.###/####-##"],
                          validation: "required|cnpj",
                          label: _vm.$t("CNPJ"),
                        },
                        model: {
                          value: _vm.company.cnpj,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "cnpj", $$v)
                          },
                          expression: "company.cnpj",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "state-registration",
                          name: "stateRegistration",
                          type: "text",
                          validation: "required",
                          maxlength: "20",
                          label: _vm.$t("Inscrição Estadual"),
                        },
                        model: {
                          value: _vm.company.stateRegistration,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "stateRegistration", $$v)
                          },
                          expression: "company.stateRegistration",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "state-registration-of-tax-substitute",
                          name: "stateRegistrationOfTaxSubstitute",
                          type: "text",
                          maxlength: "20",
                          label: _vm.$t("I.E. do Substituto Tributário"),
                          instruction: _vm.$t(
                            "Inscrição Estadual do Substituto Tributário"
                          ),
                        },
                        model: {
                          value: _vm.company.stateRegistrationOfTaxSubstitute,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.company,
                              "stateRegistrationOfTaxSubstitute",
                              $$v
                            )
                          },
                          expression:
                            "company.stateRegistrationOfTaxSubstitute",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "municipal-inscription",
                          name: "municipalInscription",
                          type: "text",
                          maxlength: "20",
                          label: _vm.$t("Inscrição Municipal"),
                        },
                        model: {
                          value: _vm.company.municipalInscription,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "municipalInscription", $$v)
                          },
                          expression: "company.municipalInscription",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "email",
                          name: "email",
                          type: "text",
                          label: _vm.$t("E-mail"),
                          validation: "required",
                        },
                        model: {
                          value: _vm.company.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "email", $$v)
                          },
                          expression: "company.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "1" } },
                    [
                      _c("p", { staticClass: "h6" }, [_vm._v(" Ativo? ")]),
                      _c("e-status-badge", {
                        attrs: { status: !!_vm.company.active, type: "yesNo" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              ref: "main-card",
              attrs: {
                "no-actions": "",
                "show-loading": _vm.loading || _vm.busy,
                title: _vm.$t("Fiscal"),
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "tax-code",
                          name: "taxCode",
                          type: "text",
                          validation: "optional|number",
                          label: _vm.$t("CNAE Fiscal"),
                        },
                        model: {
                          value: _vm.company.taxCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "taxCode", _vm._n($$v))
                          },
                          expression: "company.taxCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "tax-regime",
                          name: "taxRegime",
                          type: "vue-select",
                          validation: "required",
                          label: _vm.$t("Regime Fiscal"),
                          options: _vm.taxRegimes(),
                          placeholder: _vm.$t("Selecione"),
                        },
                        model: {
                          value: _vm.company.taxRegime,
                          callback: function ($$v) {
                            _vm.$set(_vm.company, "taxRegime", $$v)
                          },
                          expression: "company.taxRegime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "certificate-file",
                          name: "digitalCertificateLocal",
                          "show-as-label": _vm.digitalCertificateName,
                          type: "uploader",
                          deletable: "true",
                          "max-files": "1",
                          meta: false,
                          theme: "list",
                          accept: ".pfx",
                          "help-text": _vm.$t(
                            "Arraste e solte o certificado aqui, ou clique para selecionar"
                          ),
                          "delete-confirm-message": _vm.$t(
                            "Tem certeza que deseja excluir o Certificado Digital?"
                          ),
                          label: _vm.$t("Certificado Digital"),
                        },
                        model: {
                          value: _vm.digitalCertificateLocal,
                          callback: function ($$v) {
                            _vm.digitalCertificateLocal = $$v
                          },
                          expression: "digitalCertificateLocal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        class: _vm.enableDigitalCertificatePassword
                          ? "required"
                          : "",
                        attrs: {
                          id: "certificate-password",
                          name: "digitalCertificatePassword",
                          disabled: !_vm.enableDigitalCertificatePassword,
                          title: _vm.enableDigitalCertificatePassword
                            ? ""
                            : _vm.$t(
                                "Insira ou altere o certificado digital para habilitar a senha"
                              ),
                          type: "password",
                          validation: _vm.enableDigitalCertificatePassword
                            ? "required"
                            : "",
                          label: _vm.$t("Senha do Certificado Digital"),
                        },
                        model: {
                          value: _vm.company.digitalCertificatePassword,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.company,
                              "digitalCertificatePassword",
                              $$v
                            )
                          },
                          expression: "company.digitalCertificatePassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "certificate-due-date",
                          name: "digitalCertificateDueDate",
                          type: "label",
                          filter: "date",
                          label: _vm.$t("Validade do Certificado Digital"),
                        },
                        model: {
                          value: _vm.company.digitalCertificateDueDate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.company,
                              "digitalCertificateDueDate",
                              $$v
                            )
                          },
                          expression: "company.digitalCertificateDueDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("e-address", {
                    ref: "address",
                    attrs: {
                      "show-map": true,
                      "show-lat-lng": true,
                      "show-loading": _vm.loading || _vm.busy,
                    },
                    model: {
                      value: _vm.company.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.company, "address", $$v)
                      },
                      expression: "company.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "b-card-actions",
                            {
                              ref: "main-card",
                              attrs: {
                                "no-actions": "",
                                "show-loading": _vm.loading || _vm.busy,
                                title: _vm.$t("Telefones"),
                              },
                            },
                            [
                              _c("FormulateInput", {
                                ref: "telephoneGroup",
                                attrs: {
                                  name: "telephones",
                                  type: "group",
                                  "error-behavior": "live",
                                  repeatable: true,
                                  "add-label": _vm.$t("Adicionar Telefone"),
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var index = ref.index
                                      return [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { md: "5" } },
                                              [
                                                _c("FormulateInput", {
                                                  staticClass: "required",
                                                  attrs: {
                                                    id:
                                                      "company-telephone-type-" +
                                                      index,
                                                    type: "vue-select",
                                                    name: "type",
                                                    label: _vm.$t("Tipo"),
                                                    options:
                                                      _vm.telephoneTypes(),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              { attrs: { md: "7" } },
                                              [
                                                _c("FormulateInput", {
                                                  directives: [
                                                    {
                                                      name: "mask",
                                                      rawName: "v-mask",
                                                      value: [
                                                        "(##) ####-####",
                                                        "(##) #####-####",
                                                      ],
                                                      expression:
                                                        "['(##) ####-####', '(##) #####-####']",
                                                    },
                                                  ],
                                                  staticClass: "required",
                                                  attrs: {
                                                    id:
                                                      "company-telephone-number-" +
                                                      index,
                                                    type: "text",
                                                    name: "number",
                                                    validation:
                                                      "optional|min:14",
                                                    maxlength: "15",
                                                    instruction: _vm.$t(
                                                      "Exemplo: (99) 99999-9999"
                                                    ),
                                                    label: _vm.$t("Telefone"),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.company.telephones,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.company, "telephones", $$v)
                                  },
                                  expression: "company.telephones",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isEdition
                        ? _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-card-actions",
                                {
                                  ref: "main-card",
                                  attrs: {
                                    "no-actions": "",
                                    "show-loading": _vm.loading || _vm.busy,
                                    title: _vm.$t("Lojas"),
                                  },
                                },
                                [
                                  _c("b-table", {
                                    ref: "stores-table",
                                    staticClass: "table-responsive bordered",
                                    attrs: {
                                      id: "stores-table",
                                      responsive: "",
                                      striped: "",
                                      "show-empty": "",
                                      "empty-text": _vm.getEmptyTableMessage(
                                        _vm.$tc("STORE.NAME"),
                                        "female"
                                      ),
                                      items: _vm.company.stores,
                                      fields: _vm.storeFields,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(actions)",
                                          fn: function (row) {
                                            return [
                                              _c("e-grid-actions", {
                                                attrs: {
                                                  "show-delete": false,
                                                  "show-update": _vm.$can(
                                                    "Update",
                                                    "Store"
                                                  ),
                                                },
                                                on: {
                                                  update: function ($event) {
                                                    return _vm.editStore(
                                                      row.item
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                        {
                                          key: "cell(active)",
                                          fn: function (ref) {
                                            var value = ref.value
                                            return [
                                              _c("e-status-badge", {
                                                attrs: { status: value },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1729673170
                                    ),
                                  }),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12", align: "right" } },
                                        [
                                          _c("e-button", {
                                            attrs: {
                                              id: "add_store",
                                              type: "button",
                                              variant: "primary",
                                              icon: "plus",
                                              text: _vm.$t("Adicionar loja"),
                                            },
                                            on: { click: _vm.addStore },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("FAB", {
            attrs: {
              "main-tooltip": _vm.$t("Ações"),
              "main-icon": "keyboard_command_key",
              actions: _vm.actions,
              "fixed-tooltip": true,
              busy: _vm.busy,
            },
            on: {
              save: _vm.save,
              cancel: _vm.cancel,
              active: _vm.activateDeactivateCompany,
              inactive: _vm.activateDeactivateCompany,
              export: _vm.exportCompany,
              import: _vm.importCompany,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }