<template>
  <div>
    <FormulateForm
      ref="form"
      name="formCompany"
    >
      <b-card-actions
        ref="main-card"
        no-actions
        :show-loading="loading || busy"
        :title="$t('Dados Cadastrais')"
      >
        <b-row>
          <b-col md="1">
            <FormulateInput
              id="company-id"
              v-model="company.id"
              name="id"
              class="required"
              :label="$t('Id')"
              :type="isEdition ? 'label' : 'number'"
              validation="required"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="identification"
              v-model="company.identification"
              name="identification"
              class="required"
              :label="$t('Identificação')"
              placeholder="M01, F01, Q01, etc."
              type="text"
              validation="required"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="company-name"
              v-model="company.companyName"
              name="companyName"
              class="required"
              :label="$t('Razão Social')"
              type="text"
              validation="required"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="trading-name"
              v-model="company.tradingName"
              name="tradingName"
              type="text"
              class="required"
              :label="$t('Nome Fantasia')"
              validation="required"
            />
          </b-col>
          <b-col md="3">
            <e-company-combo
              id="headquarters"
              v-model="company.headquartersCompanyId"
              name="headquartersCompanyId"
              :required="false"
              :label="$t('Matriz')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="type"
              v-model="company.type"
              name="type"
              :type="isEdition && company.type === 'Kiosk' ? 'label' : 'vue-select'"
              class="required"
              validation="required"
              :label="$t('Tipo')"
              :options="companyTypes()"
              :placeholder="$t('Selecione')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="region"
              v-model="company.region"
              name="region"
              type="label"
              :label="$t('Região')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="cnpj"
              v-model="company.cnpj"
              name="cnpj"
              type="text-mask"
              class="required"
              :mask="['##.###.###/####-##']"
              validation="required|cnpj"
              :label="$t('CNPJ')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="state-registration"
              v-model="company.stateRegistration"
              name="stateRegistration"
              type="text"
              class="required"
              validation="required"
              maxlength="20"
              :label="$t('Inscrição Estadual')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="state-registration-of-tax-substitute"
              v-model="company.stateRegistrationOfTaxSubstitute"
              name="stateRegistrationOfTaxSubstitute"
              type="text"
              maxlength="20"
              :label="$t('I.E. do Substituto Tributário')"
              :instruction="$t('Inscrição Estadual do Substituto Tributário')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="municipal-inscription"
              v-model="company.municipalInscription"
              name="municipalInscription"
              type="text"
              maxlength="20"
              :label="$t('Inscrição Municipal')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="email"
              v-model="company.email"
              name="email"
              type="text"
              class="required"
              :label="$t('E-mail')"
              validation="required"
            />
          </b-col>
          <b-col md="1">
            <p class="h6">
              Ativo?
            </p>
            <e-status-badge
              :status="!!company.active"
              type="yesNo"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        ref="main-card"
        no-actions
        :show-loading="loading || busy"
        :title="$t('Fiscal')"
      >
        <b-row>
          <b-col md="3">
            <FormulateInput
              id="tax-code"
              v-model.number="company.taxCode"
              name="taxCode"
              type="text"
              validation="optional|number"
              :label="$t('CNAE Fiscal')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="tax-regime"
              v-model="company.taxRegime"
              name="taxRegime"
              type="vue-select"
              class="required"
              validation="required"
              :label="$t('Regime Fiscal')"
              :options="taxRegimes()"
              :placeholder="$t('Selecione')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <FormulateInput
              id="certificate-file"
              v-model="digitalCertificateLocal"
              name="digitalCertificateLocal"
              :show-as-label="digitalCertificateName"
              type="uploader"
              deletable="true"
              max-files="1"
              :meta="false"
              theme="list"
              accept=".pfx"
              :help-text="$t('Arraste e solte o certificado aqui, ou clique para selecionar')"
              :delete-confirm-message="$t('Tem certeza que deseja excluir o Certificado Digital?')"
              :label="$t('Certificado Digital')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="certificate-password"
              v-model="company.digitalCertificatePassword"
              name="digitalCertificatePassword"
              :class="enableDigitalCertificatePassword ? 'required' : ''"
              :disabled="!enableDigitalCertificatePassword"
              :title="
                enableDigitalCertificatePassword
                  ? ''
                  : $t('Insira ou altere o certificado digital para habilitar a senha')
              "
              type="password"
              :validation="enableDigitalCertificatePassword ? 'required' : ''"
              :label="$t('Senha do Certificado Digital')"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="certificate-due-date"
              v-model="company.digitalCertificateDueDate"
              name="digitalCertificateDueDate"
              type="label"
              filter="date"
              :label="$t('Validade do Certificado Digital')"
            />
          </b-col>
        </b-row>
      </b-card-actions>

      <b-row>
        <b-col md="6">
          <e-address
            ref="address"
            v-model="company.address"
            :show-map="true"
            :show-lat-lng="true"
            :show-loading="loading || busy"
          />
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="12">
              <b-card-actions
                ref="main-card"
                no-actions
                :show-loading="loading || busy"
                :title="$t('Telefones')"
              >
                <FormulateInput
                  v-slot="{ index }"
                  ref="telephoneGroup"
                  v-model="company.telephones"
                  name="telephones"
                  type="group"
                  error-behavior="live"
                  :repeatable="true"
                  :add-label="$t('Adicionar Telefone')"
                >
                  <b-row>
                    <b-col md="5">
                      <FormulateInput
                        :id="`company-telephone-type-${index}`"
                        type="vue-select"
                        :name="`type`"
                        class="required"
                        :label="$t('Tipo')"
                        :options="telephoneTypes()"
                      />
                    </b-col>
                    <b-col md="7">
                      <FormulateInput
                        :id="`company-telephone-number-${index}`"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        type="text"
                        :name="`number`"
                        class="required"
                        validation="optional|min:14"
                        maxlength="15"
                        :instruction="$t('Exemplo: (99) 99999-9999')"
                        :label="$t('Telefone')"
                      />
                    </b-col>
                  </b-row>
                </FormulateInput>
              </b-card-actions>
            </b-col>
            <b-col
              v-if="isEdition"
              md="12"
            >
              <b-card-actions
                ref="main-card"
                no-actions
                :show-loading="loading || busy"
                :title="$t('Lojas')"
              >
                <b-table
                  id="stores-table"
                  ref="stores-table"
                  class="table-responsive bordered"
                  responsive
                  striped
                  show-empty
                  :empty-text="getEmptyTableMessage($tc('STORE.NAME'), 'female')"
                  :items="company.stores"
                  :fields="storeFields"
                >
                  <template #cell(actions)="row">
                    <e-grid-actions
                      :show-delete="false"
                      :show-update="$can('Update', 'Store')"
                      @update="editStore(row.item)"
                    />
                  </template>
                  <template #cell(active)="{ value }">
                    <e-status-badge :status="value" />
                  </template>
                </b-table>
                <b-row class="mt-1">
                  <b-col
                    md="12"
                    align="right"
                  >
                    <e-button
                      id="add_store"
                      type="button"
                      variant="primary"
                      icon="plus"
                      :text="$t('Adicionar loja')"
                      @click="addStore"
                    />
                  </b-col>
                </b-row>
              </b-card-actions>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <FAB
        :main-tooltip="$t('Ações')"
        main-icon="keyboard_command_key"
        :actions="actions"
        :fixed-tooltip="true"
        :busy="busy"
        @save="save"
        @cancel="cancel"
        @active="activateDeactivateCompany"
        @inactive="activateDeactivateCompany"
        @export="exportCompany"
        @import="importCompany"
      />
    </FormulateForm>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { BTable, BRow, BCol } from 'bootstrap-vue'
import {
  companyDomains,
  formulateHelper,
  storeDomains,
  stringUtils,
  taxRegimes,
  telephoneTypes,
  uploader,
} from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { getUserData } from '@/auth/utils'
import {
  EAddress,
  EStatusBadge,
  FAB,
  ECompanyCombo,
  EButton,
  EGridActions,
} from '@/views/components'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BCardActions,
    EAddress,
    EStatusBadge,
    FAB,
    ECompanyCombo,
    EButton,
    EGridActions,
  },
  mixins: [
    formulateHelper,
    stringUtils,
    taxRegimes,
    uploader,
    storeDomains,
    companyDomains,
    telephoneTypes,
  ],
  data() {
    return {
      loading: false,
      busy: false,
      userData: getUserData(),
      digitalCertificateLocal: [],
      digitalCertificateChanged: false,
    }
  },
  computed: {
    ...mapState('pages/company/companyMaintain', ['company', 'apiData']),
    ...mapGetters('pages/company/companyMaintain', [
      'digitalCertificate',
      'digitalCertificateName',
    ]),
    isEdition() {
      return !!this.$router.currentRoute.params.id
    },

    enableDigitalCertificatePassword() {
      return this.digitalCertificateChanged && this.digitalCertificateLocal?.length
    },
    actions() {
      const actions = [
        {
          name: 'cancel',
          icon: 'arrow_back',
          color: 'red',
          tooltip: this.$t('Voltar'),
        },
      ]

      if (this.isEdition) {
        actions.push({
          name: 'export',
          icon: 'download',
          color: 'green',
          tooltip: this.$t('Exportar'),
        })
      } else {
        actions.push({
          name: 'import',
          icon: 'upload',
          color: 'green',
          tooltip: this.$t('Importar'),
        })
      }
      actions.push({
        name: 'save',
        icon: 'save',
        tooltip: this.$t('Salvar'),
      })
      return actions
    },
    storeFields() {
      return [
        {
          key: 'actions',
          label: this.$t('Ações'),
          class: 'text-center',
          thStyle: { width: '80px' },
        },
        {
          key: 'identification',
          label: this.$t('Identificação'),
          thStyle: { width: '80px' },
        },
        {
          key: 'tradingName',
          label: this.$t('Nome'),
          formatter: (val, key, item) => this.$options.filters.storeName(item),
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '80px' },
        },
      ]
    },
  },
  watch: {
    digitalCertificate(val) {
      this.digitalCertificateLocal = val ? [val] : []
    },
    digitalCertificateLocal(val) {
      if (!this.loading) {
        this.digitalCertificateChanged = !!val
      }
      if (!val?.length) {
        this.company.digitalCertificateDueDate = null
      }
    },
  },
  async mounted() {
    this.loading = true
    Promise.all([this.getData()]).finally(() => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions('app', { appFetchCompanies: 'fetchCompanies' }),
    ...mapActions('pages/company/companyMaintain', []),
    ...mapMutations('pages/company/companyMaintain', ['SET_COMPANY']),

    async getData() {
      if (this.$router.currentRoute.params.id) {
        try {
          this.loading = true
          await this.$store.dispatch(
            'pages/company/companyMaintain/fetchCompany',
            this.$router.currentRoute.params.id
          )
        } catch (error) {
          this.showGenericError({ error })
          this.$router.push({ name: 'company-list' })
        } finally {
          this.loading = false
        }
      }
    },

    cancel() {
      this.$router.go(-1)
      this.$store.dispatch('pages/company/companyMaintain/clearCompany')
    },

    async save() {
      this.$refs.form.showErrors()
      const addressValid = this.$refs.address.validate()
      if (this.$refs.form.isValid && addressValid) {
        // input de grupo do formulate ocorre bug com "validation required" nos inputs dentro qnd adiciona e depois remove um item.
        // Para evitar, foi feito verificação manual.
        if (!this.validateTelephones()) {
          this.showInvalidDataMessage({ message: this.$t('Em telefones') })
          return
        }

        const { telephones: apiTelephones } = this.apiData
        const { telephones } = this.company
        const notInTelephonesForm = tApi => telephones.findIndex(t => t.id === tApi.id) < 0
        const telephonesDeleted = apiTelephones
          .filter(tApi => notInTelephonesForm(tApi))
          .map(tDeleted => ({ ...tDeleted, isDeleted: true }))

        try {
          this.busy = true

          const data = {
            ...this.company,
            telephones: [...telephones, ...telephonesDeleted],
            taxCode: this.company.taxCode || null,
          }

          await this.$http({
            url: '/api/companies',
            method: this.isEdition ? 'PUT' : 'POST',
            data,
          })
          if (await this.uploadCertificate(this.company)) {
            this.showSuccess({ message: this.$t('Empresa salva com sucesso') })
            this.$router.push({ name: 'company-list' })
            this.$store.dispatch('pages/company/companyMaintain/clearCompany')
            this.$store.dispatch('pages/company/companyMaintain/fetchCompanies')
          }
          await this.appFetchCompanies()
        } catch (error) {
          this.showError({ error, message: this.$t('Não foi possível concluir a operação') })
        } finally {
          this.busy = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },

    async uploadCertificate(company) {
      if (this.digitalCertificateLocal.length && this.digitalCertificateChanged) {
        try {
          const [file] = this.digitalCertificateLocal
          await this.uploadCustom({
            apiUrl: `/api/companies/${company.id}/upload-certificate`,
            file,
            fileParamName: 'file',
            destinationPath: 'images/companies',
            params: {
              password: company.digitalCertificatePassword,
            },
          })
          return true
        } catch (error) {
          this.showError({
            error,
            title: this.$t('Erro no upload do certificado'),
            message: error.message,
          })
          return false
        }
      }
      return true
    },

    validateTelephones() {
      const { telephones } = this.company
      let isValid = true

      if (telephones.length > 0) {
        isValid = !telephones.some(t => !(t.number && t.type))
      }

      return isValid
    },

    async activateDeactivateCompany() {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const { id, active } = this.company

          const path = active ? 'deactivate' : 'activate'
          await this.$http({
            url: `/api/companies/${path}/${id}`,
            method: 'PUT',
          })
          this.getData()
          this.showSuccess({
            message: this.$t(`${active === true ? 'Inativado' : 'Ativado'} com sucesso`),
          })
          await this.appFetchCompanies()
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    exportCompany() {
      // Convert JSON object to string
      const jsonString = JSON.stringify(this.company)

      // Create a blob with the JSON string
      const blob = new Blob([jsonString], { type: 'application/json' })

      // Create a link for downloading the file
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `Company_${this.$options.filters.storeName(
        this.company
      )}_${moment().format()}.json`

      // Click the link to start downloading the file
      link.click()

      // Clean up the link and blob objects
      URL.revokeObjectURL(link.href)
      link.remove()
    },

    importCompany() {
      // Create an input element for selecting files
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'application/json'

      // Add an event listener for when a file is selected
      input.addEventListener('change', () => {
        const file = input.files[0]

        // Use a FileReader to read the contents of the file
        const reader = new FileReader()
        reader.onload = () => {
          try {
            const jsonObj = JSON.parse(reader.result)

            // Do something with the loaded JSON object

            this.SET_COMPANY(jsonObj)
          } catch (e) {
            this.showError({ message: this.$t('Não foi possível ler o arquivo') })
            console.error('Failed to parse JSON from file', e)
          }
        }
        reader.readAsText(file)
      })

      // Click the input element to trigger the file selection dialog
      input.click()
    },

    addStore() {
      this.$router.push({
        name: 'store-add',
        params: { companyId: this.company.id },
      })
    },

    editStore(store) {
      this.$router.push({
        name: 'store-maintain',
        params: { id: store.id, companyId: this.company.id },
      })
    },
  },
}
</script>
<style>
.bg-light-gray {
  background-color: #f2f2f2;
}
</style>
